import { GenericPageProps } from '../templates/generic-page.template';
import { GenericPageDataQuery } from '../../graphql-types';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';
import { mapFragmentToTagList } from './homepage.mapper';

export const mapFragmentToGenericPageHeaderProps = (
  genericpage: NonNullable<GenericPageDataQuery['cms']['genericPage']>,
  locale: AvailableLocales
): GenericPageProps['header'] => {
  return {
    title: genericpage.title ?? '',
    tags: mapFragmentToTagList(genericpage.tags),
    image: mapFragmentToImageProps(genericpage.image, locale),
  };
};
