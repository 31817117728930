import React from 'react';

import styled from '@emotion/styled';
import { CTALink } from '../atoms/cta-link';

export type TagListProps = {
  tags: string[] | undefined;
};

const TagWrapper = styled.div`
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
`;

export const TagList: React.FC<TagListProps> = ({ tags }) => {
  return tags && tags.length > 0 ? (
    <div>
      {tags.map(tag => (
        <TagWrapper key={tag}>
          <CTALink uppercase size="cta" text={tag} hover={false} />
        </TagWrapper>
      ))}
    </div>
  ) : null;
};
