import React, { useRef } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { TagList } from '../components/molecules/tag-list';
import { Image, ImageProps } from '../components/atoms/image';
import { Heading } from '../components/atoms/heading';
import { HeaderSection } from '../components/molecules/header-section';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { EditorialSection, mapEditorialSectionToComponent } from '../components/organisms/editorial-section';

export type GenericPageProps = {
  header?: {
    title: string;
    tags: string[];
    image?: ImageProps;
  };
  sections: EditorialSection[];
  showAccessibilityOptions: boolean;
};

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
`;

const PageBody = styled.div`
  margin: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 auto;
  }
`;

export const GenericPageTemplate: React.FC<GenericPageProps> = props => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoints();
  const facilitiDiv = useRef<HTMLDivElement>(null);

  if (props.showAccessibilityOptions && typeof window !== 'undefined') {
    const containsIframe = (facilitiDiv.current?.querySelectorAll?.('iframe')?.length ?? 0) > 0;

    if (!containsIframe) {
      (window as any).FACIL_ITI?.INCONTENT_MANAGER?.setup?.();
    }
  }

  return (
    <>
      {props.header && (
        <PageHeader>
          <HeaderSection>
            <Heading level="h1" text={props.header?.title} noMargin />
            <TagList tags={props.header?.tags} />
          </HeaderSection>
          <div>
            <Image {...props.header?.image} />
          </div>
        </PageHeader>
      )}

      <PageBody>
        {props.sections?.map((section, index) => {
          const id = `${section.type}-${index}`;
          const nextSection = props.sections[index + 1]?.type;
          return mapEditorialSectionToComponent(section, id, !isDesktop, theme, nextSection);
        })}
      </PageBody>
      {props.showAccessibilityOptions && (
        <div ref={facilitiDiv} data-faciliti-incontent="" style={{ height: '500px' }}></div>
      )}
    </>
  );
};
