import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';

import SEO from '../components/seo';

import { mapFragmentToEditorialSectionsProps } from '../data-mappers/editorial.mapper';
import { parseLocale } from '../locale';
import { GenericPageDataQuery } from '../../graphql-types';
import { safeApply } from '../data-mappers/utils';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { GenericPageTemplate, GenericPageProps } from '../templates/generic-page.template';
import { mapFragmentToGenericPageHeaderProps } from '../data-mappers/generic-page.mapper';
import { LocalizedSlug, useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

type GenericPagePageProps = {
  data: GenericPageDataQuery;
};

const GenericPagePage: React.FC<GenericPagePageProps> = ({ data }) => {
  const genericPage = data.cms.genericPage;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);
  const slug = data.cms.genericPage?.slug ?? '';
  const showAccessibilityOptions = slug === 'accessibility' || slug === 'accessibilite';

  const genericPageProps: GenericPageProps = {
    header: safeApply(value => mapFragmentToGenericPageHeaderProps(value, locale), genericPage),
    sections: mapFragmentToEditorialSectionsProps(genericPage, locale),
    showAccessibilityOptions,
  };

  useBreadcrumbs({
    pageTitle: genericPageProps?.header?.title,
  });

  const generateLocalizedSlugs = (data: GenericPageDataQuery): LocalizedSlug[] => {
    const localizedSlugs: LocalizedSlug[] = [];
    const genericPage = data?.cms?.genericPage;
    genericPage?.localizations?.forEach((localization: any) => {
      if (localization) {
        return localizedSlugs.push({ locale: localization.locale, slug: localization.slug });
      }
    });
    return localizedSlugs;
  };

  useLocalizedSlugs({
    slugs: generateLocalizedSlugs(data),
  });

  const seoProps = {
    title: genericPageProps?.header?.title ?? '',
    description: genericPage?.metas?.description ?? '',
    lang: locale,
  };

  return (
    <>
      <SEO {...seoProps} />
      <GenericPageTemplate {...genericPageProps} />
    </>
  );
};

export default GenericPagePage;

export const query = graphql`
  query GenericPageData($genericPageId: ID!) {
    cms {
      genericPage(id: $genericPageId) {
        ...GenericPage
        metas {
          id
          description
        }
        image {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        image_mobile {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        sections {
          __typename
          ...AlignedMarkdown
          ...TestimonialsWithImages
          ...KeyFiguresWithImage
          ...MultipleKpiCards
          ...TextOnBackgroundImage
          ...SimpleImageCard
          ...HomepageTextContentWithMedia
        }
      }
    }
  }
`;

export const genericPageFragment = graphql`
  fragment GenericPage on cms_GenericPage {
    id
    title
    excerpt
    slug
    tags {
      ...Tag
    }
    localizations {
      ...GenericPageLocalization
    }
  }

  fragment GenericPageLocalization on cms_GenericPage {
    id
    slug
    locale
  }

  fragment GenericPageThumbnail on cms_UploadFile {
    id
    name
    caption
    alternativeText
    staticFile {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }

  fragment GenericPageWithThumbnail on cms_GenericPage {
    ...GenericPage
    thumbnail {
      ...GenericPageThumbnail
    }
    thumbnail_mobile {
      ...GenericPageThumbnail
    }
  }
`;
